import { Component } from '@angular/core';
import { ApiService } from './services/api.service';
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(public apiService: ApiService) {}
  ngOnInit(){
    this.apiService.getConfig().subscribe(res => {
      // Llamada que recoge las configuraciones desde la API y las almacena en localStorage
      console.log('res :>> ', res);

      if (res["subastart-speech"]) { localStorage.setItem("activarSpeech", res["subastart-speech"]); }
      if (res["subastart-pantallaComprador"]) { localStorage.setItem("mostrarComprador", res["subastart-pantallaComprador"]); }
      if (res["subastart-pantallaGanador"]) { localStorage.setItem("mostrarGanador", res["subastart-pantallaGanador"]); }
      if (res["subastart-pantallaPrecio"]) { localStorage.setItem("pantallaPrecio", res["subastart-pantallaPrecio"]); }
      if (res["subastart-confSuba"]) { localStorage.setItem("confSuba", res["subastart-confSuba"]); }
      if (res["subastart-intervalDays"]) { localStorage.setItem("intervalDays", res["subastart-intervalDays"]); }
      if (res["subastart-intervalPages"]) { localStorage.setItem("intervalPages", res["subastart-intervalPages"]); }
      if (res["subastart-numPantallas"]) { localStorage.setItem("numPantallas", res["subastart-numPantallas"]); }
    }, (error) => {
      console.log("Error en la recepcion de datos de configuracion de pantalla.");
    });
  }
}
