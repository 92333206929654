// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendBaseUrl:"",
  APIURL: 'https://subasta-login.saecdata.net',
  MQTT_SERVER_URL: 'mqtt.saecdata.net',
  MQTT_PROTOCOL: "wss",
  MQTT_USER: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZGVudGl0eSI6IjQ1NDAiLCJzdWIiOiI0NTQwIiwiZXhwIjoyNTM0MDIzMDA3OTksInR5cGUiOiJhY2Nlc3MifQ.Ixv37yUTfyIzUNmGU1sxHNAIq88uhhAm1Ieoe_GwRP8',
  MQTT_PASSWD: '12345678',
  MQTT_PORT: 8084,
  VERSION: 1.0,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
