import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  /**
   * Código de sesión. El código se recibe por parámetro url. Se corresponde con la vendeduría. De no haber código las llamadas serán para todos.
   */
  code: any = null;

  constructor(
    private http: HttpClient,
  ) { }

  public getToken() {
    let url = environment.APIURL + '/authorize'
    let header = new HttpHeaders({ 'Authorization': 'hVs7TcMYtsZjbNU4xGVhu0q3OIWza75C625BvoL9jkE' })
    return this.http.post<any>(url, {}, { headers: header }).pipe(
      mergeMap((response: any) => {
        return of(response);
      })
    )
  }



  /**
   * Llamada para recibir todos los parametros de configuracion de subasta. Es necesario filtrar el resultado para obtener la configuracion de la pantalla.
   * @returns
   */
  public getConfig() {
    let url = environment.APIURL + '/configSubasta';
    return this.getToken().pipe(
      mergeMap((data: any) => {
        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + data['token'], 'content-type': 'application/json ' });
        let httpOptions = { headers: headers };
        return this.http.get<any>(url, httpOptions);
      }),
      tap((data: any) => {
        return data;
      }));
  }

  // Llamada para recibir la lista de los usuarios del sistema
  public getListadoUsuarios(): any {
    let finalUrl = environment.APIURL + '/listadoUsuariosBloqueables';
    return this.getToken().pipe(
      mergeMap((data: any) => {
        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + data['token'], 'content-type': 'application/json ' });
        let httpOptions = { headers: headers };
        return this.http.get<any>(finalUrl,  httpOptions);
      }),
      tap((data: any) => {
        return data;
      }));
  }
  // Llamada para recibir la lista de los lotes publicados de ese dia
  public getLotesPublicados(fecha): any {
    let finalUrl = environment.APIURL + '/lotesPublicados';
    return this.getToken().pipe(
      mergeMap((data: any) => {
        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + data['token'], 'content-type': 'application/json ' });
        let httpOptions = { headers: headers };
        return this.http.post<any>(finalUrl, {'fecha': fecha}, httpOptions);
      }),
      tap((data: any) => {
        return data;
      }));
  }

  // Llamada para recibir las 5 ultimas adjudicaciones de ese dia para un array de barcos
  public getLastAdjs(fecha,barcos): any {
    let finalUrl = environment.APIURL + '/cincoAdjsUlt';
    return this.getToken().pipe(
      mergeMap((data: any) => {
        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + data['token'], 'content-type': 'application/json ' });
        let httpOptions = { headers: headers };
        return this.http.post<any>(finalUrl, {'fecha': fecha, 'barcos': barcos}, httpOptions);
      }),
      tap((data: any) => {
        return data;
      }));
  }
}
