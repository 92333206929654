import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'conectados',
    loadChildren: () => import('./conectados/conectados.module').then(m => m.ConectadosPageModule)
  },
  {
    path: 'lotes',
    loadChildren: () => import('./prevision-lotes/prevision-lotes.module').then(m => m.PrevisionLotesPageModule)
  },
  {
    path: 'subasta',
    loadChildren: () => import('./subasta/subasta.module').then(m => m.SubastaPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./prevision-lotes/prevision-lotes.module').then(m => m.PrevisionLotesPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
